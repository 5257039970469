<script>
import { mapGetters } from 'vuex';
import formatters from '@/mixins/formatters.js';
import dateHelpers from '@/mixins/dateHelpers';
import RemoteSearchInput from '@/components/Ui/RemoteSearchInput.vue';
import { helpers, required } from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';

export default {
  name: 'SecondEquipmentMapping',
  components: {
    RemoteSearchInput,
  },
  mixins: [formatters, dateHelpers],
  props: ['myStore'],
  setup: () => ({ v$: useVuelidate() }),
  data() {
    // const estimationValidation = (rule, value, callback) => {
    //   const fEstimations = this.estimations.filter((x) => x.code === 'ПУ1'
    //     || x.code === 'ПУ2'
    //     || x.code === 'П'
    //     || x.code === 'ПУ'
    //     || x.code === 'ПН'
    //     || x.code === 'П сигнал');
    //
    //   if (this.selectedDestSeCard.estimationId === null) {
    //     return callback(new Error('Поле обязательно для заполнения'));
    //   } else if (fEstimations.some((x) => x.id === this.selectedDestSeCard.estimationId)
    //     && (this.selectedDestSeCard.technicalReasons.length > 0 || this.selectedDestSeCard.organizationalReasons.length > 0 || this.selectedDestSeCard.misoperationReasons.length > 0)) {
    //     return callback(new Error('Нельзя выбрать данную оценку, так как заполнены технические, организационные или детализированные причины неправильной работы'));
    //   } else {
    //     return callback();
    //   }
    // };
    // const secondEquipmentValidation = (rule, value, callback) => {
    //   if (this.selectedDestSeCard.secondEquipmentsInit.length < 1) {
    //     return callback(new Error('Поле обязательно для заполнения'));
    //   } else {
    //     return callback();
    //   }
    // };
    // const orgReasonsValidation = (rule, value, callback) => {
    //   const reasons = this.selectedDestSeCard.organizationalReasons;
    //   const duplicate = this.searchDuplicateOrgReasons(reasons);
    //   const missed = this.searchMissedFieldsOrgReasons(reasons);
    //   if (duplicate) {
    //     return callback(new Error('Указаны две или более одинаковые организационные причины'));
    //   }
    //   if (missed) {
    //     return callback(new Error('Все поля обязательны для заполнения'));
    //   }
    //   if (reasons.length === 0 && this.filteredEstimations.find((el) => this.selectedDestSeCard.estimationId === el.id)) {
    //     this.$refs.seCardForm.rules.organizationalReasons.required = true;
    //     return callback(new Error('Поле обязательно для заполнения'));
    //   }
    //   this.$refs.seCardForm.rules.organizationalReasons.required = false;
    //   return callback();
    // };
    return {
      loading: false,
      editMode: false,
      organizationalReasonsRequired: false,
      //   rules: {
      //     estimationId: [
      //       {
      //         validator: estimationValidation,
      //         trigger: 'blur',
      //         required: true
      //       }
      //     ],
      //     secondEquipmentName: [
      //       {
      //         validator: secondEquipmentValidation,
      //         trigger: 'blur',
      //         required: true
      //       }
      //     ],
      //     organizationalReasons:
      //       {
      //         validator: orgReasonsValidation,
      //         trigger: 'blur',
      //         required: false
      //       },
      //     technicalReasons:
      //       {
      //         trigger: 'blur',
      //         required: false,
      //         message: 'Поле обязательно для заполнения'
      //       },
      //   }
    };
  },
  validations() {
    const estimationValidation2 = (value, siblings) => {
      const fEstimations = this.estimations.filter((x) => x.code === 'ПУ1'
        || x.code === 'ПУ2'
        || x.code === 'П'
        || x.code === 'ПУ'
        || x.code === 'ПН'
        || x.code === 'П сигнал');

      if (value === null) {
        return {
          $valid: false,
          $message: 'Поле обязательно для заполнения'
        };
      } else if (fEstimations.some((x) => x.id === value)
        && (siblings.technicalReasons.length > 0 || siblings.organizationalReasons.length > 0 || siblings.misoperationReasons.length > 0)) {
        return {
          $valid: false,
          $message: 'Нельзя выбрать данную оценку, так как заполнены технические, организационные или детализированные причины неправильной работы'
        };
      } else {
        return true;
      }
    };

    const orgReasonsValidation2 = () => {
      const reasons = this.selectedDestSeCard.organizationalReasons;
      const duplicate = this.searchDuplicateOrgReasons(reasons);
      const missed = this.searchMissedFieldsOrgReasons(reasons);
      if (duplicate) {
        return {
          $valid: false,
          $message: 'Указаны две или более одинаковые организационные причины'
        };
      }
      if (missed) {
        return {
          $valid: false,
          $message: 'Все поля обязательны для заполнения'
        };
      }
      if (reasons.length === 0 && this.filteredEstimations.find((el) => this.selectedDestSeCard.estimationId === el.id)) {
        // this.$refs.cardForm.rules.organizationalReasons.required = true;
        return {
          $valid: false,
          $message: 'Поле обязательно для заполнения'
        };
      }
      // this.$refs.cardForm.rules.organizationalReasons.required = false;
      return true;
    };

    const localRules = {
      selectedDestSeCard: {
        estimationId: {
          required: helpers.withMessage('Поле обязательно для заполнения', required),
          estimationValidation2,
          $autoDirty: true
        },
        // secondEquipmentsInit: {
        //   required: helpers.withMessage('Поле обязательно для заполнения', required),
        //   $autoDirty: true
        // },
        organizationalReasons:
          {
            orgReasonsValidation2,
            $autoDirty: true
          },
        technicalReasons:
          {
            $autoDirty: true
          },

      },
    };

    if (this.selectedDestSeCard && this.filteredEstimations.find((el) => el.id === this.selectedDestSeCard.estimationId)) {
      localRules.selectedDestSeCard.technicalReasons.required = helpers.withMessage('Поле обязательно для заполнения', required);
      localRules.selectedDestSeCard.organizationalReasons.required = helpers.withMessage('Поле обязательно для заполнения', required);
    }

    return localRules;
  },
  computed: {
    ...mapGetters('identity', ['user', 'userHasRole', 'userHasRoleGroup']),
    ...mapGetters('dictionaries', ['cardInformations', 'misoperationReasons', 'estimations']),
    seCompare() {
      if (this.myStore.state.selectedSepId && this.myStore.state.selectedSeId) {
        return this.myStore.state.seCompare.get(`${this.myStore.state.selectedSepId}_${this.myStore.state.selectedSeId}`) || {};
      }
      return {};
    },
    selectedDestSeCard() {
      return this.myStore.state.selectedDestSeCard;
    },
    selectedSourceSeCard() {
      return this.myStore.state.selectedSourceSeCard;
    },
    showDestSe() {
      // return this.myStore.state.hideRepeat ? this.selectedDestSepCard.secondEquipmentCards.filter((sep) => !sep.isEqual) : this.selectedDestSepCard.secondEquipmentCards;
      return this.myStore.state.hideRepeat ? !this.myStore.state.selectedDestSeCard.isEqual : this.myStore.state.selectedDestSepCard && this.myStore.state.selectedDestSeCard;
    },
    showSourceSe() {
      return this.myStore.state.hideRepeat ? !this.myStore.state.selectedSourceSeCard.isEqual : this.myStore.state.selectedSourceSepCard && this.myStore.state.selectedSourceSeCard;
    },
    destStaffCategories() {
      return this.myStore.state.destEventCard?.staffCategories || [];
    },
    sourceStaffCategories() {
      return this.myStore.state.sourceEventCard?.staffCategories || [];
    },
    destStaffCulpabilities() {
      return this.myStore.state.destEventCard?.staffCulpabilities || [];
    },
    sourceStaffCulpabilities() {
      return this.myStore.state.sourceEventCard?.staffCulpabilities || [];
    },
    showCopyAll() {
      return !this.myStore.state.selectedDestSeCard && this.myStore.state.selectedDestSepCard && this.myStore.state.selectedSourceSeCard;
    },
    filteredEstimations() {
      return this.estimations.filter((x) => x.code === 'НЛ'
        || x.code === 'НО'
        || x.code === 'НИ'
        || x.code === 'НИ сигнал'
        || x.code === 'НЛ сигнал'
        || x.code === 'НО сигнал');
    },
    allowStaffCategories() {
      return this.myStore.state.destEventCard ? this.myStore.state.destEventCard.staffCategories.filter((x) => x.code === '00') : [];
    },
    seHideRepeat() {
      return this.myStore.state.hideRepeat;
    },
    readOnlySe() {
      return this.myStore.state.selectedDestSepCard.readOnly
        || (this.myStore.state.currentStatusId === 1 && this.userHasRoleGroup('ExternalUser'))
        || (this.myStore.state.currentStatusId === 2 && !this.userHasRoleGroup('ExternalUser'))
        || !this.myStore.state.sourceEventCard.secondEquipmentPanelCards.find((x) => x.secondEquipmentPanelId === this.myStore.state.selectedDestSepCard.secondEquipmentPanelId);
    }
    // destSes() {
      
    // },
    // sSes() {
    //   return this.myStore.state.hideRepeat ? this.selectedSourceSepCard.secondEquipmentCards.filter((sep) => !sep.isEqual) : this.selectedSourceSepCard.secondEquipmentCards;
    // },
  },
  // watch: {
  //   'selectedDestSeCard.estimationId': function (newValue) {
  //     if (newValue) {
  //       this.handleEstimation(newValue);
  //     }
  //   }
  // },
  methods: {
    addDestNewRow() {
      this.selectedDestSeCard.organizationalReasons.unshift({
        key: this.newRandomKey(),
        code: '',
        category: '',
        organization: ''
      });
    },
    newRandomKey() {
      return new Date().getTime() + Math.floor(Math.random() * 10000);
    },
    deleteDestRow(idx) {
      this.$delete(this.selectedDestSeCard.organizationalReasons, idx);
    },
    copy(name) {
      debugger;
      if (name === 'secondEquipmentName') {
        this.myStore.copySe();
        // if (!this.myStore.state.selectedDestSeCard && this.myStore.state.selectedSourceSeCard) {
        //   const addSe = JSON.parse(JSON.stringify(this.myStore.state.selectedSourceSeCard));
        //   addSe.id = '00000000-0000-0000-0000-000000000000';
        //   this.myStore.state.selectedDestSepCard.secondEquipmentCards.push(addSe);
        // }
      }
      if (this.myStore.state.selectedDestSeCard && this.myStore.state.selectedSourceSeCard) {
        this.myStore.state.selectedDestSeCard[name] = JSON.parse(JSON.stringify({ v: this.myStore.state.selectedSourceSeCard[name] })).v;

        // eslint-disable-next-line default-case
        switch (name) {
          case 'organizationalReasons':
            this.myStore.prepareOrganizationalReasons(this.myStore.state.selectedDestSeCard);
            break;
        }
        //   this.myStore.reload();
      }
    },
    // handleEstimation(value) {
    //   if (this.filteredEstimations.find((el) => el.id === value)) {
    //     this.rules.technicalReasons.required = true;
    //     this.organizationalReasonsRequired = true;
    //   } else {
    //     this.rules.technicalReasons.required = false;
    //     this.organizationalReasonsRequired = false;
    //     this.$refs.seCardForm.clearValidate();
    //   }
    // },
    searchDuplicateOrgReasons(array) {
      // eslint-disable-next-line no-shadow
      return array.map((value) => value.code + value.category + value.organization)
        .some((value, index, arr) => arr.indexOf(value) !== arr.lastIndexOf(value));
    },
    searchMissedFieldsOrgReasons(array) {
      let result = false;
      // eslint-disable-next-line func-names
      // eslint-disable-next-line consistent-return
      array.forEach((x) => {
        if (this.allowStaffCategories.find((el) => el.id === x.category)) {
          if (x.code === null || x.code === '' || x.category === null || x.category === '') {
            result = true;
          }
        } else if (x.organization === null || x.organization === '' || x.code === null || x.code === '' || x.category === null || x.category === '') {
          result = true;
        }
      });
      return result;
    },
    async validateForm() {
      try {
        await this.$refs.seCardForm.validate();
        return Promise.resolve(true);
      } catch (e) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise.reject(false);
      }
    },
    async validate() {
      return !this.v$.$invalid;
    }
  },
};
</script>

<template>
  <div v-if="showDestSe || showSourceSe">
    <el-row :gutter="5" class="mt-1">
      <el-col :span="12">
        <el-row :gutter="5" class="mt-0" v-if="showDestSe">
          <el-col :span="8" class="my-label-14">Функция УРЗА:</el-col>
          <el-col :span="16">
            <el-input
              readonly
              :class="{'my-event-card-no-compare': seCompare.secondEquipmentName }"
              size="mini"
              v-model="selectedDestSeCard.secondEquipmentName"
            ></el-input>
          </el-col>
        </el-row>
        <span v-else>&nbsp;</span>
      </el-col>
      <el-col :span="1" class="my-flex-content-center">
        <el-button size="mini" v-if="showCopyAll"
                   @click="copy('secondEquipmentName')"
                   :class="{'my-event-card-move': seCompare.secondEquipmentName }"
                   icon="el-icon-d-arrow-left" circle></el-button>
        <span v-else>&nbsp;</span>
      </el-col>
      <el-col :span="11">
        <el-row :gutter="5" class="mt-0" v-if="showSourceSe">
          <el-col :span="this.myStore.state.labelSpan" class="my-label-14">Функция УРЗА:</el-col>
          <el-col :span="this.myStore.state.dataSpan">
            <el-input
              size="mini"
              v-model="selectedSourceSeCard.secondEquipmentName"
              readonly
            ></el-input>
          </el-col>
        </el-row>
      </el-col>
    </el-row>
    <el-row :gutter="5" class="mt-1"
            v-if="!seHideRepeat ||(seHideRepeat && seCompare.estimationId)">
      <el-col :span="12">
        <el-row :gutter="5" class="mt-0" v-if="showDestSe">
          <el-col :span="this.myStore.state.labelSpan" class="my-label-14">Оценка действия:
            <span v-show="v$.selectedDestSeCard.estimationId.required"
                  style="color: #f56c6c">*</span>
          </el-col>
          <el-col :span="this.myStore.state.dataSpan">
            <el-select
              :disabled="readOnlySe"
              :class="{'my-event-card-no-compare': seCompare.estimationId }"
              popper-class="search-select"
              size="mini"
              v-model="selectedDestSeCard.estimationId" placeholder="">
              <el-option
                v-for="item in estimations"
                :key="item.id"
                :label="item.code + ' (' + item.name + ')'"
                :value="item.id">
              </el-option>
            </el-select>
            <div v-if="v$.selectedDestSeCard.estimationId.required.$invalid"
                 class="my-error">
              {{ v$.selectedDestSeCard.estimationId.required.$message }}
            </div>
          </el-col>
        </el-row>
        <span v-else>&nbsp;</span>
      </el-col>
      <el-col :span="1" class="my-flex-content-center">
        <el-button size="mini" v-if="showSourceSe && seCompare.estimationId"
                   @click="copy('estimationId')"
                   :class="{'my-event-card-move': seCompare.estimationId }"
                   icon="el-icon-d-arrow-left" :disabled="readOnlySe"
                   circle></el-button>
        <span v-else>&nbsp;</span>
      </el-col>
      <el-col :span="11">
        <el-row :gutter="5" class="mt-0" v-if="showSourceSe">
          <el-col :span="this.myStore.state.labelSpan" class="my-label-14">Оценка действия:</el-col>
          <el-col :span="this.myStore.state.dataSpan">
            <el-select disabled
                       :class="{'my-event-card-no-compare': seCompare.estimationId }"
                       popper-class="search-select"
                       size="mini"
                       v-model="selectedSourceSeCard.estimationId" placeholder="">
              <el-option
                v-for="item in estimations"
                :key="item.id"
                :label="item.code + ' (' + item.name + ')'"
                :value="item.id">
              </el-option>
            </el-select>
          </el-col>
        </el-row>
        <span v-else>&nbsp;</span>
      </el-col>

    </el-row>
    <el-row :gutter="5" class="mt-1"
            v-if="!seHideRepeat ||(seHideRepeat && seCompare.cardInformation)">
      <el-col :span="12">
        <el-row :gutter="5" class="mt-0" v-if="showDestSe">
          <el-col :span="this.myStore.state.labelSpan" class="my-label-14">Дополнительная
            информация:
          </el-col>
          <el-col :span="this.myStore.state.dataSpan">
            <el-select multiple v-model="selectedDestSeCard.cardInformation" key="destCardInformation"
                       :class="{'my-event-card-no-compare': seCompare.cardInformation }"
                       size="mini" placeholder=""
                       :disabled="readOnlySe">
              <el-option
                v-for="item in cardInformations"
                :key="item.id"
                :label="item.name"
                :value="item.id"
                :disabled="item.isDisabled"
              >
              </el-option>
            </el-select>
          </el-col>
        </el-row>
        <span v-else>&nbsp;</span>
      </el-col>
      <el-col :span="1" class="my-flex-content-center">
        <el-button size="mini" v-if="showSourceSe && seCompare.cardInformation"
                   icon="el-icon-d-arrow-left"
                   @click="copy('cardInformation')"
                   :class="{'my-event-card-move': seCompare.cardInformation }"
                   circle :disabled="readOnlySe"></el-button>
        <span v-else>&nbsp;</span>
      </el-col>
      <el-col :span="11">
        <el-row :gutter="5" class="mt-0" v-if="showSourceSe">
          <el-col :span="this.myStore.state.labelSpan" class="my-label-14">Дополнительная
            информация:
          </el-col>
          <el-col :span="this.myStore.state.dataSpan">
            <el-select multiple v-model="selectedSourceSeCard.cardInformation" key="sourceCardInformation"
                       size="mini" disabled
                       placeholder="">
              <el-option
                :class="{'my-event-card-no-compare': seCompare.cardInformation }"
                v-for="item in cardInformations"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-col>
        </el-row>
      </el-col>

    </el-row>
    <el-row :gutter="5" class="mt-1"
            v-if="!seHideRepeat ||(seHideRepeat && seCompare.technicalReasons)">
      <el-col :span="12">
        <el-row :gutter="5" class="mt-0" v-if="showDestSe">
          <el-col :span="this.myStore.state.labelSpan" class="my-label-14">Технические причины:
            <span v-if="v$.selectedDestSeCard.technicalReasons.required"
                  style="color: #f56c6c">*</span>
          </el-col>
          <el-col :span="this.myStore.state.dataSpan">
            <el-select multiple key="destTechnicalReasons"
                       size="mini"
                       v-model="selectedDestSeCard.technicalReasons"
                       popper-class="search-select"
                       :class="{'my-event-card-no-compare': seCompare.technicalReasons}"
                       :disabled="readOnlySe"
            >
              <el-option
                v-for="item in this.myStore.state.techReasons"
                :key="item.id"
                :label="'('+item.code+') '+item.name"
                :value="item.id"></el-option>
            </el-select>
            <div
              v-if="v$.selectedDestSeCard.technicalReasons.required && v$.selectedDestSeCard.technicalReasons.required.$invalid"
              class="my-error">
              {{ v$.selectedDestSeCard.technicalReasons.required.$message }}
            </div>
          </el-col>
        </el-row>
        <span v-else>&nbsp;</span>
      </el-col>
      <el-col :span="1" class="my-flex-content-center">
        <el-button size="mini" v-if="showSourceSe && seCompare.technicalReasons"
                   icon="el-icon-d-arrow-left"
                   @click="copy('technicalReasons')"
                   :class="{'my-event-card-move': seCompare.technicalReasons }"
                   circle :disabled="readOnlySe"></el-button>
        <span v-else>&nbsp;</span>
      </el-col>
      <el-col :span="11">
        <el-row :gutter="5" class="mt-0" v-if="showSourceSe">
          <el-col :span="this.myStore.state.labelSpan" class="my-label-14">Технические причины:
          </el-col>
          <el-col :span="this.myStore.state.dataSpan">
            <el-select multiple disabled key="sourceTechnicalReasons"
                       size="mini"
                       v-model="selectedSourceSeCard.technicalReasons"
                       popper-class="search-select"
                       :class="{'my-event-card-no-compare': seCompare.technicalReasons }"
            >
              <el-option
                v-for="item in this.myStore.state.techReasons"
                :key="item.id"
                :label="'('+item.code+') '+item.name"
                :value="item.id"></el-option>
            </el-select>
          </el-col>
        </el-row>
      </el-col>
    </el-row>
    <el-row :gutter="5" class="mt-1"
            v-if="!seHideRepeat ||(seHideRepeat && seCompare.misoperationReasons)">
      <el-col :span="12">
        <el-row :gutter="5" class="mt-0" v-if="showDestSe">
          <el-col :span="this.myStore.state.labelSpan" class="my-label-14">Детал. прич. непр.
            работы:
          </el-col>
          <el-col :span="this.myStore.state.dataSpan">
            <el-select popper-class="search-select" key="destMisoperationReasons"
                       size="mini" v-model="selectedDestSeCard.misoperationReasons"
                       :class="{'my-event-card-no-compare': seCompare.misoperationReasons }"
                       multiple placeholder="" :disabled="readOnlySe">
              <el-option
                v-for="item in misoperationReasons"
                :key="item.id"
                :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>
          </el-col>
        </el-row>
        <span v-else>&nbsp;</span>
      </el-col>
      <el-col :span="1" class="my-flex-content-center">
        <el-button size="mini" v-if="showSourceSe && seCompare.misoperationReasons"
                   icon="el-icon-d-arrow-left"
                   @click="copy('misoperationReasons')"
                   :class="{'my-event-card-move': seCompare.misoperationReasons }"
                   circle :disabled="readOnlySe"></el-button>
        <span v-else>&nbsp;</span>
      </el-col>
      <el-col :span="11">
        <el-row :gutter="5" class="mt-0" v-if="showSourceSe">
          <el-col :span="this.myStore.state.labelSpan" class="my-label-14">Детал. прич. непр.
            работы:
          </el-col>
          <el-col :span="this.myStore.state.dataSpan">
            <el-select popper-class="search-select" size="mini" disabled key="sourceMisoperationReasons"
                       v-model="selectedSourceSeCard.misoperationReasons"
                       multiple placeholder="">
              <el-option
                :class="{'my-event-card-no-compare': seCompare.misoperationReasons }"
                v-for="item in misoperationReasons"
                :key="item.id"
                :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>
          </el-col>
        </el-row>
      </el-col>
    </el-row>
    <el-row :gutter="5" class="mt-1"
            v-if="!seHideRepeat ||(seHideRepeat && seCompare.description)">
      <el-col :span="12">
        <el-row :gutter="5" class="mt-0" v-if="showDestSe">
          <el-col :span="this.myStore.state.labelSpan" class="my-label-14">Описание причины:
          </el-col>
          <el-col :span="this.myStore.state.dataSpan">
            <el-input size="mini" :disabled="readOnlySe"
                      :class="{'my-event-card-no-compare': seCompare.description  }"
                      v-model="selectedDestSeCard.description "
                      type="textarea"
                      :rows="3"
            ></el-input>
          </el-col>
        </el-row>
        <span v-else>&nbsp;</span>
      </el-col>
      <el-col :span="1" class="my-flex-content-center">
        <el-button :class="{'my-event-card-move': seCompare.description }"
                    v-if="seCompare.description  && showSourceSe"
                    size="mini"
                    @click="copy('description')"
                    icon="el-icon-d-arrow-left" circle
                    :disabled="readOnlySe"></el-button>
        <span v-else>&nbsp;</span>
      </el-col>
      <el-col :span="11">
        <el-row :gutter="5" class="mt-0" v-if="showSourceSe">
          <el-col :span="this.myStore.state.labelSpan" class="my-label-14">Описание причины:
          </el-col>
          <el-col :span="this.myStore.state.dataSpan">
            <el-input size="mini"
                      :class="{'my-event-card-no-compare': seCompare.description }"
                      readonly
                      v-model="selectedSourceSeCard.description"
                      type="textarea"
                      :rows="3"
            ></el-input>
          </el-col>
        </el-row>
      </el-col>
    </el-row>
    <el-row :gutter="5" class="mt-1"
            v-if="!seHideRepeat ||(seHideRepeat && seCompare.organizationalReasons)">
      <el-col :span="12">
        <div v-if="showDestSe">
          <el-row :gutter="5" class="mt-0">
            <el-col :span="24">
              <label class="el-form-item__label">
                Организационные причины: <span
                v-if="v$.selectedDestSeCard.organizationalReasons.required"
                style="color: #f56c6c">*</span>
              </label>
              <el-button
                :disabled="readOnlySe"
                circle
                icon="el-icon-plus"
                size="small"
                class="p-1 mb-1 ml-2"
                @click="addDestNewRow">
              </el-button>
            </el-col>
          </el-row>
          <el-row :gutter="5" class="mt-0">
            <el-col :span="24">
              <el-table
                :data="this.selectedDestSeCard.organizationalReasons"
                v-loading="this.loading"
                size="mini"
                :default-sort="{prop: 'code', order: 'ascending'}"
                style="width: 100%"
              >
                <el-table-column prop="code" label="Код причины">
                  <template slot-scope="props">
                    <el-select size="small" popper-class="search-select" v-model="props.row.code"
                               placeholder="Выберете код причины">
                      <template v-for="group in destStaffCulpabilities">
                        <el-option-group
                          v-if="group.children.length > 0"
                          :key="group.id"
                          :label="group.name">
                          <el-option
                            v-for="item in group.children"
                            :key="item.id"
                            :label="item.code + ' (' + item.name + ')'"
                            :value="item.code">
                          </el-option>
                        </el-option-group>
                        <el-option
                          v-else
                          :key="group.id"
                          :label="group.code + ' (' + group.name + ')'"
                          :value="group.code">
                        </el-option>
                      </template>
                    </el-select>
                  </template>
                </el-table-column>
                <el-table-column prop="category" label="Категория персонала">
                  <template slot-scope="props">
                    <el-select size="small" popper-class="search-select"
                               v-model="props.row.category" placeholder="Выберете категорию">
                      <el-option
                        v-for="item in destStaffCategories"
                        :key="item.id"
                        :label="item.code + ' (' + item.name + ')'"
                        :value="item.id">
                      </el-option>
                    </el-select>
                  </template>
                </el-table-column>
                <el-table-column prop="organization" label="Организация">
                  <template slot-scope="scope">
                    <remote-search-input
                      v-model="scope.row.organization"
                      :key="scope.row.key"
                      searchUrl="/api/organizations/SearchByName"
                      size="small"
                      return-prop="id"
                      placeholder="Выберите организацию"
                      :show-when-init="true"
                      :initial-value="scope.row.organizationName"
                      :start-length=1
                      :allow-create="false"
                      @label-update="scope.row.organizationName = $event"
                    />
                  </template>
                </el-table-column>
                <el-table-column width="42px">
                  <template slot-scope="scope">
                    <el-button @click="deleteDestRow(scope.$index)" type="text" :disabled="readOnlySe"
                               icon="el-icon-delete"></el-button>
                  </template>
                </el-table-column>
              </el-table>
            </el-col>
          </el-row>
          <div class="my-error"
               v-if="v$.selectedDestSeCard.organizationalReasons.required && v$.selectedDestSeCard.organizationalReasons.required.$invalid">
            {{ v$.selectedDestSeCard.organizationalReasons.required.$message }}
          </div>
          <div class="my-error"
               v-if="v$.selectedDestSeCard.organizationalReasons.orgReasonsValidation2.$invalid">
            {{
              v$.selectedDestSeCard.organizationalReasons.orgReasonsValidation2.$response.$message
            }}
          </div>
        </div>
        <span v-else>&nbsp;</span>
      </el-col>
      <el-col :span="1" class="my-flex-content-center">
        <el-button v-if="showSourceSe && seCompare.organizationalReasons" size="mini"
                   icon="el-icon-d-arrow-left"
                   @click="copy('organizationalReasons')"
                   :class="{'my-event-card-move': seCompare.organizationalReasons }"
                   circle></el-button>
        <span v-else>&nbsp;</span>
      </el-col>
      <el-col :span="11">
        <div v-if="showSourceSe">
          <el-row :gutter="5" class="mt-0">
            <el-col :span="24">
              <label class="el-form-item__label">
                Организационные причины: <span v-if="organizationalReasonsRequired"
                                               style="color: #f56c6c">*</span>
              </label>
            </el-col>
          </el-row>
          <el-row :gutter="5" class="mt-0">
            <el-col :span="24">
              <el-table
                :data="this.selectedSourceSeCard.organizationalReasons"
                v-loading="this.loading"
                size="small"
                :default-sort="{prop: 'code', order: 'ascending'}"
                style="width: 100%"
              >
                <el-table-column prop="code" label="Код причины">
                  <template slot-scope="props">
                    <el-select :disabled="true" size="small" popper-class="search-select"
                               v-model="props.row.code"
                               placeholder="Выберете код причины">
                      <template v-for="group in sourceStaffCulpabilities">
                        <el-option-group
                          v-if="group.children.length > 0"
                          :key="group.id"
                          :label="group.name">
                          <el-option
                            v-for="item in group.children"
                            :key="item.id"
                            :label="item.code + ' (' + item.name + ')'"
                            :value="item.code">
                          </el-option>
                        </el-option-group>
                        <el-option
                          v-else
                          :key="group.id"
                          :label="group.code + ' (' + group.name + ')'"
                          :value="group.code">
                        </el-option>
                      </template>
                    </el-select>
                  </template>
                </el-table-column>
                <el-table-column prop="category" label="Категория персонала">
                  <template slot-scope="props">
                    <el-select :disabled="true" size="small" popper-class="search-select"
                               v-model="props.row.category" placeholder="Выберете категорию">
                      <el-option
                        v-for="item in sourceStaffCategories"
                        :key="item.id"
                        :label="item.code + ' (' + item.name + ')'"
                        :value="item.id">
                      </el-option>
                    </el-select>
                  </template>
                </el-table-column>
                <el-table-column prop="organization" label="Организация">
                  <template slot-scope="scope">
                    <remote-search-input
                      v-model="scope.row.organization"
                      :key="scope.row.key"
                      :disabled="true"
                      searchUrl="/api/organizations/SearchByName"
                      size="small"
                      return-prop="id"
                      placeholder="Выберите организацию"
                      :show-when-init="true"
                      :initial-value="scope.row.organizationName"
                      :start-length=1
                      :allow-create="false"
                      @label-update="scope.row.organizationName = $event"
                    />
                  </template>
                </el-table-column>
                <el-table-column width="42px">
                  <template v-slot="scope">
                    <el-button v-if="editMode" @click="deleteRow(scope.$index)" type="text"
                               icon="el-icon-delete"></el-button>
                  </template>
                </el-table-column>
              </el-table>
            </el-col>
          </el-row>
        </div>
      </el-col>
    </el-row>
  </div>
</template>


<style>
.el-main {
  overflow-y: auto !important;
}

.my-event-card-no-compare .el-input__inner {
  color: #D65A31 !important;
}

.my-event-card-no-compare .el-textarea__inner {
  color: #D65A31 !important;
}

.my-event-card-move .el-icon-d-arrow-left {
  color: #D65A31;
}

</style>
