import axios from 'axios';

export default {
    getFunctionCard: (id) => axios.get('/api/FunctionCards/GetFunctionCard', { params: { id } }),
    createFunctionCard: (model) => axios.post('/api/FunctionCards/CreateFunctionCard', model),
    updateFunctionCard: (model) => axios.post('/api/FunctionCards/UpdateFunctionCard', model),
    deleteFunctionCard: (id) => axios.delete(`/api/FunctionCards/DeleteFunctionCard/${id}`),

    checkUserDataFunctionCards: (id) => axios.get('/api/FunctionCards/CheckUserDataFunctionCards', { params: { id } }),
    checkSecondEquipmentFunctionCards: (secondEquipmentPanelId, eventCardId) => axios.post('/api/FunctionCards/CheckSecondEquipmentFunctionCards',   
        { 
            secondEquipmentPanelId,
            eventCardId 
        }),
};
