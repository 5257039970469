import axios from 'axios';

export default {
  getFunctionCard: (id) => axios.get('/api/ExtFunctionCards/GetExtFunctionCard', { params: { id } }),
  updateFunctionCard: (model) => axios.post('/api/ExtFunctionCards/UpdateExtFunctionCard', model),
  deleteFunctionCard: (id) => axios.delete(`/api/ExtFunctionCards/DeleteExtFunctionCard/${id}`),
  createFunctionCard: (model) => axios.post('/api/ExtFunctionCards/CreateExtFunctionCard', model),
  checkSecondEquipmentExtFunctionCards: (secondEquipmentPanelId, eventCardId) => axios.post('/api/ExtFunctionCards/CheckSecondEquipmentExtFunctionCards',   
    { 
        secondEquipmentPanelId,
        eventCardId 
    })

  // getFunctionCard: (id) => axios.get('/api/UserDataFunctionCards/GetUserDataFunctionCard', { params: { id } }),
  // updateFunctionCard: (model) => axios.post('/api/UserDataFunctionCards/UpdateUserDataFunctionCard', model),
  // deleteFunctionCard: (id) => axios.delete(`/api/UserDataFunctionCards/DeleteUserDataFunctionCard/${id}`),
  // createFunctionCard: (model) => axios.post('/api/UserDataFunctionCards/CreateUserDataFunctionCard', model),
};
